import React, {useContext} from 'react';
import {TextField, makeStyles} from '@material-ui/core';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';

interface TextInputProps {
  name: string;
  type: string;
  label: string;
  value: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  touched?: boolean;
  error?: string;
  multiline?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  type,
  label,
  value,
  handleChange,
  handleBlur,
  touched,
  error,
  multiline,
}) => {
  const {theme} = useContext(GlobalStateContext);

  const useStyles = makeStyles({
    inputContainer: {
      marginBottom: '30px',
      '& fieldset': {
        borderRadius: 0,
        borderWidth: theme == 'regular' ? 1 : 2,
        borderColor: theme == 'regular' ? '#fdc30a' : theme == 'blackOnWhite' ? '#000' : '#fff',
      },
      '& label': {
        fontSize: theme == 'regular' ? '1.2rem' : '2rem',
        color: theme == 'regular' ? '#464646' : theme == 'blackOnWhite' ? '#000' : '#fff',
      },
      '& .Mui-focused': {
        color: theme == 'regular' && '#464646',
      },
      '& textarea': {
        minHeight: `${multiline && '72px'}`,
      },
    },
    inputRoot: {
      fontSize: theme == 'regular' ? '1.2rem' : '2rem',
      color: theme == 'regular' ? '#464646' : theme == 'blackOnWhite' ? '#000' : '#fff',
    },
    helperText: {
      fontSize: theme == 'regular' ? '1.2rem' : '2rem',
      color: theme == 'regular' ? '#464646' : theme == 'blackOnWhite' ? '#000' : '#fff',
    },
  });

  const classes = useStyles();

  return (
    <TextField
      name={name}
      type={type}
      label={label}
      value={value}
      onChange={handleChange}
      error={touched && Boolean(error)}
      helperText={touched && error}
      onBlur={handleBlur}
      multiline={multiline}
      variant="outlined"
      fullWidth
      className={classes.inputContainer}
      InputProps={{classes: {root: classes.inputRoot}}}
      FormHelperTextProps={{classes: {root: classes.helperText}}}
    />
  );
};

export default TextInput;
