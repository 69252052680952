import React, {useContext} from 'react';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import classes from './submit-button.module.css';

const SubmitButton: React.FC = () => {
  const {theme} = useContext(GlobalStateContext);

  return (
    <button type="submit" className={clsx(classes.form_btn, theme !== 'regular' && classes.form_btn_black_and_white)}>
      Задати питання
    </button>
  );
};

export default SubmitButton;
